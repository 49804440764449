import * as React from "react";
import { PageProps, graphql } from "gatsby";

import Layout from "../components/layout";
import { useTranslation } from "react-i18next";
import {
  COMPANY_EMAIL,
  COMPANY_PHONE_NUMBER,
  COMPANY_PHONE_NUMBER_CALL,
} from "../config";
import { ContactFormField } from "../components/contactFormField";
import { ContactFormCheckboxField } from "../components/contactFormCheckboxField";
import { useContactForm } from "../hooks/useContactForm";
import { ContactForm } from "../../netlify/functions/submit-contact-form";

type DataProps = {
  site: {
    buildTime: string;
  };
};

const ContactUs: React.FC<PageProps<DataProps>> = ({ location }) => {
  const { t } = useTranslation();
  const [isSending, response, submitContactForm, reCaptchaElement] =
    useContactForm();

  return (
    <Layout
      seoTitle={t("contact.title")}
      seoDescription={t("contact.headline")}
      location={location}
    >
      <div className="container">
        <section className="page-header">
          <h2>{t("contact.headline")}</h2>
          <h5>{t("contact.description")}</h5>
        </section>
        <section className="contact-content">
          <div className="contact-widget media">
            <img src="/images/icon-5.png" alt="book" width="40px" />
            <div className="media-body">
              <h6 className="widget-title">Inspecto Sp. z o.o.</h6>
              <p className="widget-content">ul. Świętokrzyska 30/63,</p>
              <p className="widget-content">00-116 Warszawa, Polska</p>
              <p className="widget-content">NIP: 5771986272</p>
              <p className="widget-content">REGON: 380813711</p>
            </div>
          </div>
          <div className="contact-widget media">
            <img src="/images/icon-4.png" alt="monitor" width="50px" />
            <div className="media-body">
              <h6 className="widget-title">
                <a href={`mailto:${COMPANY_EMAIL}`}>{COMPANY_EMAIL}</a>
              </h6>
              <a
                className="widget-content"
                href={`tel:${COMPANY_PHONE_NUMBER_CALL}`}
              >
                {COMPANY_PHONE_NUMBER}
              </a>
            </div>
          </div>
        </section>
        <section className="contact-form-wrapper">
          <form
            method="POST"
            action="/.netlify/functions/submit-contact-form"
            onSubmit={submitContactForm}
          >
            <div className="row">
              <ContactFormField<ContactForm>
                className="col-md-6"
                fieldName="fullName"
              />
              <ContactFormField<ContactForm>
                className="col-md-6"
                fieldName="companyName"
              />
            </div>
            <div className="row">
              <ContactFormField<ContactForm>
                className="col-md-6"
                fieldName="email"
                type="email"
              />
              <ContactFormField<ContactForm>
                className="col-md-6"
                fieldName="phone"
                required={false}
              />
            </div>
            <div className="row">
              <ContactFormField<ContactForm>
                className="col-md-6"
                fieldName="fleetSize"
                options={[
                  {
                    label: t("contact.contactForm.fleetSize"),
                    value: "",
                  },
                  {
                    label: t("contact.contactForm.fleetSizeOptions.small"),
                    value: "small",
                  },
                  {
                    label: t("contact.contactForm.fleetSizeOptions.medium"),
                    value: "medium",
                  },
                  {
                    label: t("contact.contactForm.fleetSizeOptions.large"),
                    value: "large",
                  },
                ]}
              />
              <ContactFormField<ContactForm>
                className="col-md-6"
                fieldName="referrer"
                required={false}
              />
            </div>

            <div className="row">
              <div className="form-group col-12">
                <label htmlFor="message">
                  {t("contact.contactForm.message")}
                </label>
                <textarea
                  name="message"
                  id="message"
                  className="form-control"
                  rows={7}
                  placeholder={`${t("contact.contactForm.message")}...`}
                ></textarea>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <ContactFormCheckboxField<ContactForm> fieldName="gdprAgreement" />
              </div>
              <div className="col-sm-12">
                <ContactFormCheckboxField<ContactForm> fieldName="commercialOfferAgreement" />
              </div>
            </div>
            <div className="mb-3">{reCaptchaElement}</div>
            {response === "ok" ? (
              <div className="alert alert-success mb-5" role="alert">
                {t("contact.responseMessage.success")}
              </div>
            ) : response === "notOk" ? (
              <div className="alert alert-danger mb-5" role="alert">
                {t("contact.responseMessage.failure")}
              </div>
            ) : null}

            <div className="text-center">
              <button
                type="submit"
                className="btn btn-primary mb-4"
                disabled={isSending}
              >
                {isSending
                  ? t("contact.contactForm.sendButtonLoadingText")
                  : t("contact.contactForm.sendButton")}
              </button>
              <p className="form-footer-text">
                {t("contact.contactForm.wellGetBackToYou")}
              </p>
            </div>
          </form>
        </section>
      </div>
    </Layout>
  );
};

export default ContactUs;

export const query = graphql`
  query ($language: String!) {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5FDJ7N26","enableWebVitalsTracking":true,"includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":630,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"removeAccents":true,"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gatsby Starter Blog","short_name":"GatsbyJS","start_url":"/","background_color":"#ffffff","display":"minimal-ui","icon":"static/images/inspecto-checkbox.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fe889a32f9ef9bb4ac2737c5c96992c8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"generateDefaultLanguagePage":true,"redirect":false,"siteUrl":"https://getinspecto.com","localeJsonSourceName":"locale","languages":["en","pl","de"],"defaultLanguage":"pl","trailingSlash":"always","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-translate-urls/gatsby-browser.js'),
      options: {"plugins":[],"translations":{"en":{"urls.blog":"#page-not-available-in-this-language","urls.contact":"contact","urls.privacy-policy":"privacy-policy","urls.cookies-policy":"cookies-policy","urls.gdpr":"gdpr-information-clause","urls.faq":"faq","urls.about-us":"about-us","urls.application-rules":"application-rules","urls.create-inspecto-account":"create-inspecto-account","urls.functions":"functions"},"pl":{"urls.blog":"blog","urls.contact":"kontakt","urls.privacy-policy":"polityka-prywatnosci","urls.cookies-policy":"polityka-cookie","urls.gdpr":"klauzula-informacyjna-rodo","urls.faq":"faq","urls.about-us":"o-nas","urls.application-rules":"regulamin-aplikacji","urls.create-inspecto-account":"zaloz-konto-w-inspecto","urls.functions":"funkcje"},"de":{"urls.blog":"#page-not-available-in-this-language","urls.contact":"kontakt","urls.privacy-policy":"datenschutzrichtlinie","urls.cookies-policy":"cookie-richtlinie","urls.gdpr":"datenschutzinformationen","urls.faq":"faq","urls.about-us":"ueber-uns","urls.application-rules":"anwendungsregeln","urls.create-inspecto-account":"inspecto-konto-erstellen","urls.functions":"funktionen"}},"defaultLocale":"pl","prefix":"urls.","forceTrailingSlash":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
